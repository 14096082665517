@font-face {
    font-family: "plex";
    src: url("./font/IBMPlexSerif-Regular.ttf");
    font-weight: bold;
  }

  #smallText {
    font-size: 1vw;
  }
 

.navLink {
    list-style: none;
    background-color: bisque;
    border-radius: 2vw;
    
    padding: 1vw;
}


::selection {
    background:white;
  }



.poemText{
    font-size: 2.5vw;
    line-height: 2.2vw;
}
aside{
    display: flex;
    justify-content: center;
    align-items: center;
}

