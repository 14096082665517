

  .videos {
    width:50vw;
    margin-bottom: 20px;
    border-radius: 14px;
    box-shadow: 14px 14px 10px rgba(0, 0, 0, 0.326);
  }
  .AlignCenter {
    font-family: "Jura";
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

legend{text-align: center;}



#buttonDiv{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

#headerButtonBox {
  display: flex;
  justify-content: flex-end; /* Align everything to the right */
  align-items: center;
  flex-direction: row-reverse;
  
}

#slideButtons {
  display: flex;
  transition: opacity 0.3s ease, transform 0.3s ease;
  /*position: absolute;  Make it absolute so it aligns to the right */
  right: 0; /* Keep the buttons flush with the right edge */
  z-index: 0; /* Ensure buttons are behind the toggle button */
}

#slideButtons.hidden {
  opacity: 0;
  transform: translateX(100%); /* Slide out to the right */
  pointer-events: none; /* Disable interaction */
}

#slideButtons.visible {
  opacity: 1;
  transform: translateX(0); /* Slide in from the right */
}

.toggleButtonBox {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  margin:1vw;
  /*position:absolute;
  top:17%;
  right: 0;  Keep it flush with the right edge */
  z-index: 1; /* Make sure it stays above the other buttons */
  background-color: #01133f;
}












#arranger{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column  ;
}


.App {
  Display:flex;
  flex-direction: column;
  justify-content: center;
}


body{
  margin:0; 
 }

 
#logo {
  width:100%;
  background-color: #01133f;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#navbar {
  position:fixed;
  top:0;
  background-color: #01133f;
  color:white;
  list-style: none;
  font-family: "Jura";
  display:flex;
  justify-content: center;
  align-items: center;
  margin:0;
  font-size: 1.4vw;
  width:100%;
  padding: 0;
  z-index:1001;
}
#mobileNav {display:none;}
h3{
  color:#01133f;
  text-align: center;
  font-size: 1.5vw;
  clear:both;
}
li {
  display:inline-block;
  margin-left: 1vw;

  margin-right: 1vw;
}

#slideButtons.visible + .toggleButtonBox {
  transform: translateX(-365%); /* Move the toggle button to the right to replace the buttons */
}





@media only screen and (max-width: 767px) {

#aboutText {
  margin:3vw 4vw;
}

#mobileNav.open {
  display: block; /* Show the nav when the button is clicked */
  transform: translateX(0); /* Slide in from the left */
}

#mobileNav li {
  margin-bottom: 7vw;
  margin-top: 6vw;
  font-size: 3vw;
}

#navbar {
  display: none;
}
#logoDiv {margin-top: 0; margin-bottom: 3vw;}

#headerButtonBox{
  position:absolute;
  right:0;
}

/*REACT POEMS MOBILE CSS*/
.poemContainer{
  width:100%;
  padding:0 !important;
  margin:0 !important;
  margin-left: 10px !important;
  padding-bottom: 1vw !important;
  padding-top: 1vw !important;
  max-width: none !important;
}

/*END REACT POEMS MOBILE CSS*/

.toggleButtonBox {
top:6.3%;
border-radius: 25%;
}
}