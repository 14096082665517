/* Dark mode colors:
#030637 -- header background - plain text - highlight
#3C0753
#720455
#910A67 -- emphasized text - highlight text
Light mode colors:
#01133f
#02c8f07b
#02c8f0
#021442
*/
 

/* DarkModeToggle.css */
#moon-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease, filter 0.3s ease;
  color:white;
}
.dark-mode-toggle{
  background-color: #01133f;
}

button:active #moon-icon {
  transform: scale(0.9); /* Adds a slight press effect */
}

html[data-theme="dark"] {
  background-color: #030637;
 
}

html[data-theme="dark"] .hamburger{
color:#910A67;
background-color: #3C0753; 
border-color: #3C0753;
}

html[data-theme="dark"] #settings {
  stroke: #910A67 !important;
 
}

html[data-theme="dark"] .toggleButtonBox{
  background-color: #3C0753 !important;
}


html[data-theme="dark"] .ProjectText {
   color: #3C0753;
}
html[data-theme="dark"] #logo {
  background-color: #030637;
}

html[data-theme="dark"] .ProjectNames {
  color: #720455;
}
html[data-theme="dark"] #wordsLogo {
  color: #3C0753;
}
html[data-theme="dark"] #charactersLogo {
  color: #910A67;
}

html[data-theme="dark"] li:hover {
  background-image: linear-gradient(rgba(131, 3, 3, 0.263), rgba(49, 2, 2, 0.08));
}
html[data-theme="dark"] nav a {
  color:#910A67;
}
html[data-theme="dark"] #navbar {
  background-color: #030637;
}
html[data-theme="dark"] .webLinks {
  color:#910A67;
  background-color:#3C0753;
}
html[data-theme="dark"] legend {
  color: #3C0753;
}

html[data-theme="dark"] fieldset {
  border-color: #3C0753;
}
html[data-theme="dark"] #points {
  color: #3C0753;
}

/* POEMS DARK MODE */
html[data-theme="dark"] .dAZYal {
  background-color: #3C0753;
  color:#910A67;
  border-color:#720455;
}
html[data-theme="dark"] #showPoemsButton {
  background-color: #3C0753 !important;
  color:#910A67;
  border-color: #910A67 !important;
}
html[data-theme="dark"]  .poemList{
  background-color: #3C0753 !important;
  color:#910A67;
  border-color: #910A67 !important;
}
html[data-theme="dark"] .poemContainer {
  background-color: #2601194e !important;
  color:#910A67 !important;
}
html[data-theme="dark"] .poemContainer h2 {
  color:#910A67 !important;
}
html[data-theme="dark"] h1 {
  color:#910A67
}
/*END POEMS DARK MODE*/
html[data-theme="dark"] .css-1qb8o-MuiToggleButtonGroup-root{
  background-color: #3C0753 !important;
  
}
html[data-theme="dark"] .fontButtonCircle {
  background-color:#910A67 !important;
}

html[data-theme="dark"] .dark-mode-toggle {
  background-color: #3C0753;
  border-color: #910A67 ;

}





body[data-font-size="smallFont"] .ProjectText {
  font-size: 1.5vw;
}

body[data-font-size="mediumFont"] .ProjectText {
  font-size: 2vw;
}

body[data-font-size="largeFont"] .ProjectText {
  font-size: 2.5vw;
}


body[data-font-size="smallFont"] .ProjectNames {
 font-size: 3.5vw;
}

body[data-font-size="mediumFont"] .ProjectNames {
  font-size: 4vw;
}

body[data-font-size="largeFont"] .ProjectNames {
  font-size: 4.5vw;
}


#logoDiv {
  margin-top: 3vw;
  font-family: 'NewAm';
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 3vw;
}
#charactersLogo {
  color: #02c8f0;
  font-size: 5vw;
  font-weight: 900;
}
#logoColon {
  font-family:'Open';
  display:inline;
  font-size:5vw;
  margin-right: 4px;
}
#wordsLogo {
  color: white;
  margin-top: 1%;
  margin-left: .7vw;
  font-size: 2vw;
  line-height: 1.8vw;
  letter-spacing: .3vw;
  text-align: left;
}

#highlight{
     font-size: 2.6vw;
}

::selection {
  background: #021442;
  color:#02c8f0;
}

html {
  background-color: #f8f8ff;
  scroll-behavior: smooth;
}

nav a {
  color:inherit;
  text-decoration: none;
  text-align: center;
  margin:0;
  padding: 0 1vw 1vw 1vw;
}
nav a:link {
  color:white;
  font-weight:bold;
}
nav a:visited {
  color:white;
  font-weight:bold;
}
nav a:hover {
  color: #02c8f07b;
}
nav a:active {
  color: #02c8f0;
}

li:hover {
  background-image: linear-gradient(rgba(255, 255, 255, 0.263), rgba(255, 255, 255, 0.08));
  color:#02c8f0;
  border-radius: 5px;
}

.ProjectText {
  color: #01133f;
  
  width:80%;
  margin-bottom: 1.5vw;
}

.ProjectNames {
  margin:0;
  padding:0;
  margin-top:1.5vw;
  margin-bottom: 1.5vw;
  
  font-weight: bold;
  color:#02c8f0;
  width: 100%;
  text-align: center;
}

.Collapsible {
  display:flex;
}
.Collapsible__trigger {
  font-family: "Jura";
  background-color: #01133f48;
  text-align: center;
  margin-right: 2vw;
  margin-bottom: 1vw;
  padding:2px;
  font-size: 1.2vw;
  border-radius: 8px;
}
.Collapsible__contentInner {
  width:60%;
  margin: 0 auto;
  float:left;
}
.Collapsible__contentOuter {
  margin:0;
  padding:0;
}
#codeBlock {
  font-family: "Jura";
  display:flex;
  flex-direction: column;
  margin-left: 6vw;
  border-radius: 10px;
  font-size: 1.2vw;
}
code {font-size: 1.2vw;}

.webLinks{
  color:inherit;
  text-decoration: none;
  text-align: center;
  margin:0;
  padding: .7vw 1vw .7vw 1vw;
  font-size: 3vw;
  margin-top: 1vw;
  margin-bottom: 1vw;
  background-color: #01133f48;
  border-radius: .5vw;
}
.webLinks:link {
  color:#01133f;
  font-weight:bold;
}
.webLinks:visited {
  color:#01133f;
  font-weight:bold;
}
.webLinks:hover {
  color: #02c8f0;
}
.webLinks:active {
  color: #02c8f0;
}


#headshot {
  border-radius: 50%;
  width: 10vw;
  height: 10vw;
  object-fit: cover;
  transition: transform 0.3s ease;
  margin:0vw 3vw;
}
#headshot:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.flexDiv{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.hamburger{display:none;}
#points{font-size: 2vw;}




@media only screen and (max-width: 767px) {
  #headshot {
    width:20vw;
    height:20vw;
  }
#mobileNav {
  display: none; /* Initially hidden */
  position: fixed;
  top: 0;
  left: 0;
  background-color: #01133f;
  color: white;
  list-style: none;
  font-family: "Jura";
  height: 100%;
  width: 60%; /* Adjust width of the sidebar */
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 2vw;
  border-radius: 0px 0 5px 0;
  padding: 0;
  z-index: 1001;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%); /* Initially off-screen */
}

#flexDiv{
  display:flex;
  flex-direction: column;
  margin-top: 13vw;
}

.hamburger {
  display:block;
  position: fixed;
  top: 10px;
  left: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1002; /* Ensure the hamburger button is above the header */
  color:white;
  background-color: #01133f;
  border-radius: 15%;
}

#aboutDiv{margin:0;}
#downloadLink{font-size: 3vw;}
.cSharpProjectText { font-size: 3vw; }
.Collapsible__trigger { font-size: 2vw; }
#vbDiv { width: 80%; }
h3 { font-size: 3vw; }
#submit { font-size: 2vw; width: 12vw; height: 4vw; }
.ProjectText {font-size: 3vw;}
#points{font-size: 3vw;}
#resumeText{ font-size: 3vw;}
#highlight{font-size: 3.6vw;}

/*Logo Mobile sytles*/
#logoDiv {
  margin-top: 3vw;
  padding-bottom: 9vw;
  padding-top:5vw;
}
#charactersLogo {
  font-size: 8vw;
}
#logoColon {
  font-family:'Open';
  display:inline;
  font-size:8vw;
  margin-right: 4px;
}
#wordsLogo {
  font-size: 5vw;
  line-height: 4.7vw;
  letter-spacing: .5vw;
}
/*End Logo Mobile sytles*/

body[data-font-size="smallFont"] .ProjectText {
  font-size: 3vw;
}

body[data-font-size="mediumFont"] .ProjectText {
  font-size: 3.5vw;
}

body[data-font-size="largeFont"] .ProjectText {
  font-size: 4vw;
}


body[data-font-size="smallFont"] .ProjectNames {
 font-size: 4.5vw;
}

body[data-font-size="mediumFont"] .ProjectNames {
  font-size: 5vw;
}

body[data-font-size="largeFont"] .ProjectNames {
  font-size: 5.5vw;
}


#settings{
  width: 15px;
  height: 15px;
}
#fontCircleA{
  width: 5px !important;
  height: 5px !important;
}
#fontCircleB{
  width: 7px !important;
  height: 7px !important;
}
#fontCircleC{
  width: 9px !important;
  height: 9px !important;
}
.css-gn0oko-MuiButtonBase-root-MuiToggleButton-root{
    padding:5px !important;
}

#slideButtons.visible{align-items: center; }

#slideButtons.visible + .toggleButtonBox {
  transform: translateX(-185%); /* Move the toggle button to the right to replace the buttons */
}
.toggleButtonBox img {width:23px; height: 23px;}
.dark-mode-toggle{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 4vw;
  height: 4.4vw;
}
#moon-icon{
  width:10px;
}
}