@font-face {
  font-family: "Jura";
  src: url("../../images/fonts/Jura-VariableFont_wght.ttf");
  font-weight: bold;
}
h1{
  margin:0;
    padding:0;
    margin-top:1.5vw;
    margin-bottom: 1.5vw;
    font-size: 2vw;
    font-weight: bold;
    color:#02c8f0;
    width: 100%;
    text-align: center;
    font-family: "Jura";
}
    .art-display-container {
        margin-left: 5vw;
        margin-right: 5vw;
        padding-top: 20px;
      }
      
      .category-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 20px;
      }
      
      .category-button {
        padding: 10px 20px;
        margin: 5px;
        cursor: pointer;
        background: #ccc;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 1rem;
        transition: background-color 0.3s;
      }
      
      .category-button.active {
        background-color: #007BFF;
      }
      
      @media (max-width: 768px) {
        .art-display-container {
          margin-left: 13vw;
          margin-right: 3vw;
        }
      
        .category-button {
          font-size: 0.9rem;
          padding: 8px 15px;
        }
      }
      
      @media (max-width: 480px) {
        .category-button {
          font-size: 0.8rem;
          padding: 6px 12px;
        }
      }
      